import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Mon étoile" />
    <h3 className='underline-title'>Mon étoile</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je la vois<br />
        <span className="par-starting"></span>mon étoile<br />
        Elle plafonne non loin de l'astre du jour <br />
        La vois-tu aussi?<br />
      </p>
      <p>
        Ferme les yeux<br />
        Concentre-toi <br />
        Regarde et vois sans tes yeux <br />
        La vois-tu maintenant? <br />
      </p>
      <p>
        Elle brille <br />
        <span className="par-starting"></span>brille de trois quarts et demi de son éclat <br />
        <span className="par-starting"></span>Sa parcelle-iota demeure rembrunie encore par le pénombre<br />
      </p>
      <p>
        Bientot elle éclatera <br />
        <span className="par-starting"></span>éclatera de toute sa splendeur <br />
      </p>
      <p>
        Elle brillera <br />
        <span className="par-starting"></span>brillera <br />
        <span className="par-starting"></span>brillera pour toujours <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
